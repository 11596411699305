<template>
  <div>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn
        v-if="editable"
        small
        class="mb-2 mr-2"
        color="primary"
        @click="saveInputParameters()"
        >Save Input Parameters</v-btn
      >
    </div>
    <template v-for="(item, i) in parameter">
      <div :key="i" v-if="!item.hidden">
        <div class="d-flex pa-2 hoverable align-start">
          <span class="mr-4">{{ i + 1 }}.</span>
          <div class="">
            <span class="font-weight-bold">{{ item.code }}</span>
            <v-icon>mdi-circle-small</v-icon>
            <span>{{ item.name }}</span>
            <!-- <span>{{ item._id }}</span> -->
          </div>
          <v-spacer></v-spacer>
          <div style="max-width: 200px" class="mr-4">
            <v-chip small class="mr-1" label v-for="(v,k) in item.verification" :key="k">{{v.code}}</v-chip>
          </div>
          <div>
            <div style="width: 250px">
              <div v-if="item.type === 'number'" class="parameter-input">
                <lb-number
                  v-model="parametervalue[item._id]"
                  :hidedetails="true"
                  :disabled="!editable"
                />
              </div>
              <div v-else-if="item.type === 'string'" class="parameter-input">
                <lb-string
                  v-model="parametervalue[item._id]"
                  :hidedetails="true"
                  :disabled="!editable"
                />
              </div>
              <div v-else-if="item.type === 'date'" class="parameter-input">
                <lb-date v-model="parametervalue[item._id]" :hidedetails="true" :disabled="!editable"/>
              </div>
              <div v-else-if="item.type === 'year'" class="parameter-input">
                <lb-year v-model="parametervalue[item._id]" :hidedetails="true" :disabled="!editable"/>
              </div>
              <div v-else-if="item.type === 'month'" class="parameter-input">
                <lb-month
                  v-model="parametervalue[item._id]"
                  :hidedetails="true"
                  :disabled="!editable"
                />
              </div>
              <div v-else-if="item.type === 'time'" class="parameter-input">
                <lb-time v-model="parametervalue[item._id]" :hidedetails="true" :disabled="!editable"/>
              </div>
              <div v-else-if="item.type === 'daterange'" class="parameter-input">
                <lb-daterange
                  v-model="parametervalue[item._id]"
                  :hidedetails="true"
                  :disabled="!editable"
                />
              </div>
              <div v-else-if="item.type === 'timerange'" class="parameter-input">
                <lb-timerange
                  v-model="parametervalue[item._id]"
                  :hidedetails="true"
                  :disabled="!editable"
                />
              </div>
              <div
                v-else-if="
                  [
                    'numberarray',
                    'stringarray',
                    'datearray',
                    'yeararray',
                    'montharray',
                    'timearray',
                    'daterangearray',
                    'timerangearray',
                  ].indexOf(item.type) > -1
                "
                class="parameter-input"
              >
                <lb-list
                  :allowrepeat="true"
                  :type="item.type"
                  v-model="parametervalue[item._id]"
                  :hidedetails="true"
                  :disabled="!editable"
                />
              </div>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    parameter: {
      type: Array,
      required: true,
    },
    parametervalue: {
      type: Object,
      required: true,
    },
    apiParam: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    saveInputParameters() {
      this.$emit('startloading');
      let objectInput = { inputparameter: this.parametervalue };
      this.axios
        .post(this.apiParam, {
          data: objectInput,
        })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Input Parameters Updated");
            this.$emit('reload');
          } else throw new Error("Error updating input parameters");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.$emit('stoploading');
        });
    },
    // saveInputParameters() {
    //   console.log(this.apiParam,"apiParam--->",this.parametervalue);
    // },
  },
};
</script>